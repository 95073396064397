import { createWebHistory, createRouter } from "vue-router";

import store from '@/store'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  if (!authRequired) {
    return next()
  }

  // 회원 정보 확인
  const us = new URL(window.location.href)
  const token = us.searchParams.get('token')
  store.commit('auth/SET_TOKEN', token)

  next()
})

export default router
