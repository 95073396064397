// api
import Auth from './auth'
import Game from './game'
import Site from './site'
import Util from './util'

export default {
    install: (app) => {
        app.config.globalProperties.$API = {
            auth: new Auth(),
            game: new Game(),
            site: new Site(),
            util: new Util()
        }
    }
}
