<template>
  <div>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>

import SocketClient from "@/libraries/socket-client"

export default {
  name: 'App',
  components: {},
  data: function () {
    return {
      socket: null,
      flagDisconnect: null,
      disconnected: false,
      logout: null,
      // disconnectTimer: null,
    }
  },
  meta: {
    title: 'TENCNT'
  },
  async created() {

  },
  async mounted() {
    const us = new URL(window.location.href)
    const token = us.searchParams.get('token')
    if (!token) return
    let host = process.env.VUE_APP_SOCKET_SERVER_URL
    this.socket = new SocketClient(host, token)
    this.socket.onConnected = this.socketOnConnected
    this.socket.onDisconnected = this.socketOnDisconnected
    this.socket.onMessage = this.socketOnMessage
    this.socket.onError = this.socketOnError
    this.socket.sendMessage = this.socketSend
  },
  methods: {
    async myInfo() {
      try {
        const result = await this.$API.game.myInfo('');
        if (result.code !== 'OK') {
          throw result
        }

        this.$store.commit('auth/SET_CURRENT_USER', result.user)

        return true
      } catch (e) {
        this.$log.error(e)
        return false
      }
    },
    socketOnConnected() {
      this.$log.debug('connected!')

      this.myInfo();
      const hostname = window.location.hostname
      let routes
      let sendData;
      if (hostname === '127.0.0.1') {
        routes = this.$route
        if (routes.name === '/game/video') {
          sendData = {
            type: 'place',
            route: 'game',
            table_key: routes.query.tableKey
          }
        } else {
          sendData = {
            type: 'place',
            route: 'lobby'
          }
        }
      } else {
        routes = window.location
        let pathname = routes.pathname.split('/')
        if (pathname[1] === 'game') {
          sendData = {
            type: 'place',
            route: 'game',
            table_key: pathname[3]
          }
        } else {
          sendData = {
            type: 'place',
            route: 'lobby'
          }
        }
      }
      this.socketSend(JSON.stringify(sendData))
      //소켓 끊겼을 때 테스트
      // this.disconnectTimer = setTimeout(() => {
      //   this.socket.disconnect();
      //   this.$log.debug('Socket disconnected after 10 seconds');
      // }, 10000);
    },
    socketOnDisconnected() {
      this.$log.debug('---', 'disconnected')

      if (this.disconnected === false) {
        this.flagDisconnect = setTimeout(() => {
          this.$router.push({
            path: '/error/500',
            query: {logoutData: this.$store.getters['auth/user'].logout},
          });
        }, 3000);
      }
    },
    socketOnMessage(message) {
      if (message.code) {
        console.log('***', message)
      }
      if (message.command === 'duplicated_user') {
        this.disconnected = true
        this.$router.push({
          path: '/error',
          query: {logoutData: this.$store.getters['auth/user'].logout},
        });
      }
      if (message.type === 'kick') {
        const hostname = window.location.hostname
        let routes
        if (hostname === '127.0.0.1') {
          routes = this.$route
          document.location.href = `/${routes.params.companyKey}?token=${this.$store.getters['auth/token']}`
        } else {
          routes = window.location
          let pathname = routes.pathname.split('/')
          document.location.href = `/${pathname[2]}?token=${this.$store.getters['auth/token']}`
        }
      }

    },
    socketOnError(e) {
      if (e.message === 'INVALID_TOKEN' || e.message === 'STATE_BLOCKED' || e.message === 'STATE_UNREGISTERED' || e.message === 'STATE_UNVERIFIED') {
        this.socket.disconnect()
      }
    },
    socketSend(e) {
      if (this.socket) {

        this.socket.sendData(e)
      }
    }
  }
}
</script>
