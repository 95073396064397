export default [
    {
        path: "/",
        name: "home",
        meta: {
            authRequired: false,
        },
        component: () => import("../views/game/index"),
    },
    {
        path: "/:companyKey",
        name: "lobby",
        meta: {
            authRequired: true,
        },
        component: () => import("../views/game/lobby"),
    },
    {
        path: "/game/:companyKey/:tableKey",
        name: "baccarat-game",
        meta: {
            authRequired: true,
        },
        component: () => import("../views/game/game"),
    },
    {
        path: "/game/:companyKey/:tableKey/wide",
        name: "baccarat-wide-game",
        meta: {
            authRequired: true,
        },
        component: () => import("../views/game/game-wide"),
    },
    {
        path: "/game/video",
        name: "game-video",
        meta: {
            authRequired: true,
        },
        component: () => import("../views/game/video"),
    },
];
