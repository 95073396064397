import axios from 'axios'
import store from '../store'
axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

class TAPIClient {
  async _request (method, url, data) {

    // let host = process.env.VUE_APP_URL
    let host = process.env.VUE_APP_API_SERVER_URL

    // host = process.env.VUE_APP_URL
    if (host[host.length-1] !== '/') host += '/'
    url = host + url
    const tokenKey = `authorization`
    const token = store.getters['auth/token']

    try {
      const headers = {
        accept: 'application/json',
        'content-type': 'application/json',
        'Cache-Control': 'no-store',
        Pragma: 'no-store',
        Expires: '0'
      }
      headers[tokenKey] = token

      const result = await axios({
        method: method.toUpperCase(),
        url: url,
        data: data,
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: headers,
        timeout: 600000, // 5초
        withCredentials: false
      })

      if (result.status !== 200) {
        return { code: result.status, message: result.message }
      }

      if (result.data.code === 'MAINTENANCE') {
        // 점검중 페이지로 이동
        window.localStorage.setItem('maintenance', JSON.stringify({active: true, desc: result.data.msg, untilTime: new Date(result.data.untilTime)}))
        document.location.href='/maintenance'
        return { code: 0 }
      }

      return result.data
    } catch (e) {
      if (e.response && e.response.data && e.response.data.code === 'MAINTENANCE') {
        // 점검중 페이지로 이동
        window.localStorage.setItem('maintenance', JSON.stringify({ desc: e.response.data.desc, untilTime: new Date(e.response.data.untilTime) }))
        document.location.href='/maintenance'
        return { code: 'OK' }
      } else {
        return e.response ? e.response.data : { code: 'EXCEPTION' }
      }
    }
  }

  async _requestSendFile (method, url, data) {

    // let host = process.env.VUE_APP_URL
    let host = host = process.env.VUE_APP_API_SERVER_URL

    if (host[host.length-1] !== '/') host += '/'
    url = host + url
    const token = store.getters['auth/token']

    try {
      const headers = {
        "Content-Type": "multipart/form-data",
      }
      headers.authorization = token

      const result = await axios({
        method: method.toUpperCase(),
        url: url,
        data: data,
        headers: headers,
        timeout: 600000, // 5초
        withCredentials: false
      })

      if (result.status !== 200) {
        return { code: result.status, message: result.message }
      }

      if (result.data.code === 'MAINTENANCE') {
        // 점검중 페이지로 이동
        window.localStorage.setItem('maintenance', JSON.stringify({active: true, desc: result.data.msg, untilTime: new Date(result.data.untilTime)}))
        document.location.href='/maintenance'
        return { code: 0 }
      }

      return result.data
    } catch (e) {
      if (e.response && e.response.data && e.response.data.code === 'MAINTENANCE') {
        // 점검중 페이지로 이동
        window.localStorage.setItem('maintenance', JSON.stringify({ desc: e.response.data.desc, untilTime: new Date(e.response.data.untilTime) }))
        document.location.href='/maintenance'
        return { code: 'OK' }
      } else {
        return e.response ? e.response.data : { code: 'EXCEPTION' }
      }
    }
  }

  async _requestOther (method, url, data) {
    const tokenKey = `authorization`
    const token = store.getters['auth/token']

    try {
      const headers = {
        accept: 'application/json',
        'content-type': 'application/json',
        'Cache-Control': 'no-store',
        Pragma: 'no-store',
        Expires: '0'
      }
      headers[tokenKey] = token

      const result = await axios({
        method: method.toUpperCase(),
        url: url,
        data: data,
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: headers,
        timeout: 600000, // 5초
        withCredentials: false
      })

      if (result.status !== 200) {
        return { code: result.status, message: result.message }
      }

      if (result.data.code === 'MAINTENANCE') {
        // 점검중 페이지로 이동
        window.localStorage.setItem('maintenance', JSON.stringify({active: true, desc: result.data.msg, untilTime: new Date(result.data.untilTime)}))
        document.location.href='/maintenance'
        return { code: 0 }
      }

      return result.data
    } catch (e) {
      if (e.response && e.response.data && e.response.data.code === 'MAINTENANCE') {
        // 점검중 페이지로 이동
        window.localStorage.setItem('maintenance', JSON.stringify({ desc: e.response.data.desc, untilTime: new Date(e.response.data.untilTime) }))
        document.location.href='/maintenance'
        return { code: 'OK' }
      } else {
        return e.response ? e.response.data : { code: 'EXCEPTION' }
      }
    }
  }

  async get(url, data, toOther = false) {
    if (toOther === true) {
      return await this._requestOther('GET', url, data)
    } else {
      return await this._request('GET', url, data)
    }
  }

  async post(url, data, toOther = false) {
    if (toOther === true) {
      return await this._requestOther('POST', url, data)
    } else {
      return await this._request('POST', url, data)
    }
  }

  async send(url, data) {
    return await this._requestSendFile('POST', url, data)
  }
}

export default TAPIClient
