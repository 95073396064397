import { io } from 'socket.io-client'

class SocketClient {
    _socket
    _url
    _token

    constructor(url, token) {
        localStorage.debug = '*'
        this._url = url
        this._token = token

        this._socket  = io(this._url, {
            transports: ['websocket'],
            autoConnect: true,
            auth: {
                authorization: token
            }
        })
    }

    set onConnected (f) {
        this._socket.on('connect', f)
    }

    set onDisconnected (f) {
        this._socket.on('disconnect', f)
    }

    set onMessage (f) {
        this._socket.on('message', f)
    }

    set onError (f) {
        this._socket.on('connect_error', f)
    }

    connect() {
        this._socket.connect()
    }

    disconnect() {
        this._socket.disconnect(true)
    }

    sendData(data) {
        this._socket.emit('message', data)
    }
}

export default SocketClient;