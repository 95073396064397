import APIClient from '@/libraries/api-client'

class User extends APIClient {

    async captcha() {
        const t = new Date().getTime()
        let result = await this.get(`api/captcha?_t=${t}`)
        return result
    }

    async login (username, password, captchaKey, captchaText) {
        const t = new Date().getTime()
        return await this.post(`api/login?_t=${t}`, {username, password, captchaKey, captchaText})
    }

    async refresh() {
        const t = new Date().getTime()
        return await this.get(`api/mypage/refresh?_t=${t}`)
    }
}

export default User
