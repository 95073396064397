import APIClient from '@/libraries/api-client'
import { Howl } from 'howler'

class Game extends APIClient {
    constructor() {
        super()
        this.originalPositions = {}
        this.audioPlayer = new AudioPlayer()
    }

    isMobile () {
        // return /.*Android.*|.*iPad.*|.*iPhone.*/i.test(window.navigator.userAgent)
        return /.*Android.*|.*iPhone.*/i.test(window.navigator.userAgent)
    }

    isIPhone () {
        return /.*iPhone.*/i.test(window.navigator.userAgent)
    }
    getChipMoneyUnit (value) {
        let result = value + '만'
        if (value < 1000) {
            result = Math.round( value/ 100 ) + '백'
        } else if (value >= 1000 && value < 10000) {
            result = Math.round( value/ 1000 ) + '천'
        } else if (value >= 10000) {
            result = Math.round( value/ 10000 ) + '만'
        }

        return result
    }

    getFormattedMoney(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    drawCircle(ctx, x, y, radius, lineWidth, clr, isFill) {
        ctx.beginPath() 
        ctx.arc(x, y, radius, 0, 2 * Math.PI)
        ctx.strokeStyle = clr 
        ctx.lineWidth = lineWidth 
        ctx.stroke()
        if (isFill) {
            ctx.fillStyle = clr
            ctx.fill()
        }
    }

    drawText(ctx, x, y, txt, fontSize, clr) {
        ctx.font = fontSize + "px Arial", ctx.fillStyle = clr, ctx.textAlign = "center", ctx.textBaseline = "middle", ctx.fillText(txt, x, y)
    }

    drawLine(ctx, x1, y1, x2, y2, lineWidth, clr) {
        ctx.beginPath()
        ctx.moveTo(x1, y1)
        ctx.lineTo(x2, y2)
        ctx.strokeStyle = clr
        ctx.lineWidth = lineWidth
        ctx.stroke()
    }

    showPopup(elm, sec) {
        this.playSoundClick()
        if (elm) {
            // 모든 팝업 숨기기
            document.querySelectorAll('.popup').forEach(function(popup) {
                popup.style.display = 'none'
            })

            // 특정 팝업 보이기
            var targetPopup = document.getElementById(elm)
            if (targetPopup) {
                targetPopup.style.display = 'block'
            }

            // 시간이 지정되었다면 자동으로 팝업 닫기
            var timeConf = sec * 1000
            if (timeConf > 0) {
                const self = this
                setTimeout(function () {
                    self.closePopup(elm)
                }, timeConf)
            }
        }
    }

    closePopup (elm) {
        this.playSoundClick()
        if (elm) {
            var element = document.getElementById(elm)
            if (element) {
                // 페이드아웃 효과를 위한 CSS 설정
                element.style.transition = 'opacity 0.5s ease'
                element.style.opacity = '0'

                // transition이 끝난 후 요소 숨기기
                setTimeout(function() {
                    element.style.display = 'none'
                    // 초기 상태로 opacity 재설정 (다음 사용을 위해)
                    element.style.opacity = '1'
                }, 500) // 0.5초 후 실행 (transition 시간과 일치)
            }
        }
    }

    toggleFullScreen () {
        this.playSoundClick()

        // this.$root.$emit(EVENT_SOUND_PLAY, SOUND_CLICK)
        if (!document.fullscreen) {
            if(document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if(document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen()
            } else if(document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen()
            }
        } else {
            document.exitFullscreen()
        }
    }

    openConfig () {
        this.playSoundClick()

        const configMenu = document.querySelector('.config-menu')

        if (configMenu) {
            const isVisible = window.getComputedStyle(configMenu).display !== 'none'

            if (isVisible) {
                configMenu.style.display = 'none'
            } else {
                configMenu.style.display = 'block'
            }
        }
    }

    openConfigGame () {
        this.playSoundClick()

        const configMenu = document.querySelector('.pg4_config')

        if (configMenu) {
            const isVisible = window.getComputedStyle(configMenu).display !== 'none'

            if (isVisible) {
                configMenu.style.display = 'none'
            } else {
                configMenu.style.display = 'block'
            }
        }
    }

    animateRight(element, target, duration) {
        // 원래 위치 저장
        if (!this.originalPositions[element.id]) {
            this.originalPositions[element.id] = element.style.right
        }
        
        const start = element.getBoundingClientRect().right
        const distance = target - start
        let startTime = null

        function step(timestamp) {
            if (!startTime) startTime = timestamp
            const progress = timestamp - startTime
            const percentage = Math.min(progress / duration, 1)

            element.style.right = start + distance * percentage + 'px'

            if (progress < duration) {
                window.requestAnimationFrame(step)
            }
        }

        window.requestAnimationFrame(step)
    }

    animateLeft(element, duration) {
        const target = this.originalPositions[element.id] ? parseFloat(this.originalPositions[element.id]) : element.getBoundingClientRect().width
        const start = parseFloat(getComputedStyle(element).right)
        const distance = target - start
        let startTime = null
        const self = this

        function step(timestamp) {
            if (!startTime) startTime = timestamp
            const progress = timestamp - startTime
            const percentage = Math.min(progress / duration, 1)

            element.style.right = start + distance * percentage + 'px'

            if (progress < duration) {
                window.requestAnimationFrame(step)
            } else {
                // 애니메이션이 끝나면 원래 위치로 설정
                element.style.right = self.originalPositions[element.id] || ''
            }
        }

        window.requestAnimationFrame(step)
    }

    openMobileMenu () {
        this.playSoundClick()
        const menuList = document.getElementById('menu-list')
        if (menuList) {
            this.animateRight(menuList, 0, 200)
        }
    }

    closeMobileMenu () {
        this.playSoundClick()
        const menuList = document.getElementById('menu-list')
        if (menuList) {
            this.animateLeft(menuList, 200)
        }
    }

    playSoundBanker () {
        this.playSound('banker')
    }

    playSoundBeep () {
        this.playSound('beep')
    }

    playSoundBet () {
        this.playSound('bet')
    }

    playSoundBetEnd () {
        this.playSound('betend')
    }

    playSoundBetStart () {
        this.playSound('betstart')
    }

    playSoundChip () {
        this.playSound('chip')
    }

    playSoundClick () {
        this.playSound('click')
    }

    playSoundPlayer () {
        this.playSound('player')
    }

    playSoundTie () {
        this.playSound('tie')
    }

    playSoundWin () {
        this.playSound('win')
    }

    playBG () {
        // 설정에서 배경 재생 확인
        const active = localStorage.getItem('soundBG')
        if (active !== 'true') return
        this.stopSound()
        const idx = this.audioPlayer.sounds.findIndex((a) => a.name === 'bgmusic')
        if (idx > -1) {
            this.audioPlayer.sounds[idx].howl.play()
        }
    }

    playSound (name) {
        // 설정에서 음향 재생 확인
        const active = localStorage.getItem('soundEffect')
        if (active !== 'true') return

        // this.stopSound()
        const idx = this.audioPlayer.sounds.findIndex((a) => a.name === name)
        if (idx > -1) {
            this.audioPlayer.sounds[idx].howl.play()
        }
    }

    stopSound () {
        for(const key in this.audioPlayer.sounds) {
            const sound = this.audioPlayer.sounds[key]
            sound.howl.stop()
        }
    }
}

class AudioPlayer {
    constructor() {
        this.sounds = [
            { name: 'banker', howl: new Howl({ src: ['/sounds/ps/banker.mp3'], loop: false, html5: true })},
            { name: 'beep', howl: new Howl({ src: ['/sounds/ps/beep.mp3'], loop: false, html5: true })},
            { name: 'bet', howl: new Howl({ src: ['/sounds/ps/bet.mp3'], loop: false, html5: true })},
            { name: 'betend', howl: new Howl({ src: ['/sounds/ps/betend.mp3'], loop: false, html5: true })},
            { name: 'betstart', howl: new Howl({ src: ['/sounds/ps/betstart.mp3'], loop: false, html5: true })},
            { name: 'bgmusic', howl: new Howl({ src: ['/sounds/ps/bgmusic.mp3'], loop: true, html5: true })},
            { name: 'chip', howl: new Howl({ src: ['/sounds/ps/chip.mp3'], loop: false, html5: true })},
            { name: 'click', howl: new Howl({ src: ['/sounds/ps/click.mp3'], loop: false, html5: true })},
            { name: 'dragon', howl: new Howl({ src: ['/sounds/ps/dragon.mp3'], loop: false, html5: true })},
            { name: 'player', howl: new Howl({ src: ['/sounds/ps/player.mp3'], loop: false, html5: true })},
            { name: 'tie', howl: new Howl({ src: ['/sounds/ps/tie.mp3'], loop: false, html5: true })},
            { name: 'tiger', howl: new Howl({ src: ['/sounds/ps/tiger.mp3'], loop: false, html5: true })},
            { name: 'win', howl: new Howl({ src: ['/sounds/ps/win.mp3'], loop: false, html5: true })}
        ]
    }
}

export default Game
