export default class TMutex {
  constructor() {
    this.locks = new Map();
    this.waitingQueue = new Map();
  }

  async acquire(resource, timeout = 30000) {
    const startTime = Date.now();

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (!this.locks.has(resource)) {
        this.locks.set(resource, true);
        return true;
      }

      if (Date.now() - startTime > timeout) {
        return false;
      }

      await this._wait(resource);
    }
  }

  async release(resource) {
    this.locks.delete(resource);
    this._notifyWaiting(resource);
  }

  _wait(resource) {
    return new Promise(resolve => {
      if (!this.waitingQueue.has(resource)) {
        this.waitingQueue.set(resource, []);
      }
      this.waitingQueue.get(resource).push(resolve);
    });
  }

  _notifyWaiting(resource) {
    const waiting = this.waitingQueue.get(resource) || [];
    const next = waiting.shift();
    if (next) {
      next();
    }
    if (waiting.length === 0) {
      this.waitingQueue.delete(resource);
    }
  }
}
