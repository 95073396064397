// const cookiePrefix = process.env.VUE_APP_COOKIE_PREFIX
export const state = {
    currentUser: null,
    token: '',
    verified: false
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        // saveState(`${cookiePrefix}.auth.user`, newValue)
    },
    SET_CASH(state, newValue) {
        state.currentUser.cash = newValue
    },
    SET_TOKEN(state, token) {
        state.token = token
        // localStorage.setItem(`${cookiePrefix}.token`, token)
    }
}

export const getters = {
    user(state) {
        return state.currentUser
    },
    token(state) {
        // return window.localStorage.getItem(`${cookiePrefix}.token`)
        return state.token
    },
    verified(state) {
        return state.verified
    }
}

export const actions = {

}
