import APIClient from '@/libraries/api-client'

class Game extends APIClient {
    async users () {
        const t = new Date().getTime()
        return await this.post(`site/users?_t=${t}`, {})
    }

    async play (id, company) {
        const t = new Date().getTime()
        return await this.post(`site/play?_t=${t}`, { id, company })
    }
}

export default Game
