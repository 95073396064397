import Utility from './utility'
import Game from './game'
import Error from './error'

export default [
    ...Game,
    ...Utility,
    ...Error,

    // 오류페이지(404)
    {
      path: "/:catchAll(.*)",
      redirect: "/error/404"
    }
];