import APIClient from '@/libraries/api-client'

class Game extends APIClient {
    async myInfo (round) {
        const t = new Date().getTime()
        return await this.post(`game/my/info?_t=${t}`, {round})
    }

    async bet (companyKey, tableKey, roundId, minmax, target) {
        const t = new Date().getTime()
        return await this.post(`game/${companyKey}/${tableKey}/bet?_t=${t}`, {round: roundId, target, minmax,is_mobile: false})
    }

    async result (companyKey, tableKey, roundId) {
        const t = new Date().getTime()
        return await this.post(`game/${companyKey}/${tableKey}/result?_t=${t}`, {round: roundId})
    }

    async cancel (companyKey, tableKey, roundId) {
        const t = new Date().getTime()
        return await this.post(`game/${companyKey}/${tableKey}/cancel?_t=${t}`, {round: roundId})
    }

    async balance () {
        const t = new Date().getTime()
        return await this.post(`game/my/balance?_t=${t}`, {})
    }

    async companies() {
        const t = new Date().getTime()
        return await this.post(`game/companies?_t=${t}`, {})
    }

    async tables(companyKey) {
        const t = new Date().getTime()
        return await this.post(`game/${companyKey}/tables?_t=${t}`, {})
    }

    async table(companyKey, tableKey) {
        const t = new Date().getTime()
        return await this.post(`game/${companyKey}/${tableKey}/table?_t=${t}`, {})
    }

    async status(companyKey, tables) {
        const t = new Date().getTime()
        return await this.post(`game/${companyKey}/status?_t=${t}`, { tables })
    }

    async betHistory (params) {
        const t = new Date().getTime()
        return await this.post(`game/history/bet?_t=${t}`, params)
    }

    async getVideoUrl (host, ck, tk) {
        const t = new Date().getTime()
        return await this.get(`${host}vod/${ck}/auth/${tk}?_t=${t}`, {}, true)
    }
}

export default Game
