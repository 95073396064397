import crypto from 'crypto'
import { partial } from 'filesize'
const filesize = partial({ base: 2, standard: 'jedec' })

String.prototype.repeat = function (len) { let s = ''; let i = 0; while (i++ < len) { s += this } return s }
String.prototype.toZeroFill = function (len) { return '0'.repeat(len - this.length) + this }
String.prototype.toCapitalize = function () { return this.charAt(0).toUpperCase() + this.slice(1) }

Date.prototype.toLocalTime = function (f) {
  if (!this.valueOf()) return ' '

  const weekName = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  const d = this

  return f.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|zzz|a\/p)/gi, function ($1) {
    let h
    switch ($1) {
      case 'YYYY': return d.getFullYear()
      case 'YY': return (d.getFullYear() % 1000).toString().toZeroFill(2)
      case 'MM': return (d.getMonth() + 1).toString().toZeroFill(2)
      case 'DD': return d.getDate().toString().toZeroFill(2)
      case 'E': return weekName[d.getDay()]
      case 'HH': return d.getHours().toString().toZeroFill(2)
      case 'hh': {
        h = d.getHours() % 12
        return (h ? h : 12).toString().toZeroFill(2)
      }
      case 'mm': return d.getMinutes().toString().toZeroFill(2)
      case 'ss': return d.getSeconds().toString().toZeroFill(2)
      case 'zzz': return d.getMilliseconds().toString().toZeroFill(3)
      case 'a/p': return d.getHours() < 12 ? 'am' : 'pm'
      default: return $1
    }
  })
}

String.prototype.toCamelCase = function () {
  return this
    .split(/[-_]/)
    .map((word, index) => {
      if (index === 0) {
        return word
      }
      return (
        word.charAt(0).toUpperCase() +
        word.slice(1)
      )
    })
    .join("")
}

String.prototype.format = function () {
  let args = arguments
  return this.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] !== 'undefined'
      ? args[number]
      : match
  })
}

Date.prototype.toUTCTime = function (f) {
  if (!this.valueOf()) return ' '

  let weekName = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  let d = this

  return f.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|zzz|a\/p)/gi, function ($1) {
    let h
    switch ($1) {
      case 'YYYY': return d.getUTCFullYear()
      case 'YY': return (d.getUTCFullYear() % 1000).toString().toZeroFill(2)
      case 'MM': return (d.getUTCMonth() + 1).toString().toZeroFill(2)
      case 'DD': return d.getUTCDate().toString().toZeroFill(2)
      case 'E': return weekName[d.getUTCDay()]
      case 'HH': return d.getUTCHours().toString().toZeroFill(2)
      case 'hh': {
        h = d.getUTCHours() % 12
        return (h ? h : 12).toString().toZeroFill(2)
      }
      case 'mm': return d.getUTCMinutes().toString().toZeroFill(2)
      case 'ss': return d.getUTCSeconds().toString().toZeroFill(2)
      case 'zzz': return d.getUTCMilliseconds().toString().toZeroFill(3)
      case 'a/p': return d.getUTCHours() < 12 ? '오전' : '오후'
      default: return $1
    }
  })
}

Number.prototype.toThousands = function () {
  return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

Number.prototype.toFloat = function (len = 2) {
  return parseFloat(Number(this).toFixed(len))
}

String.prototype.toFloat = function (len = 2) {
  return parseFloat(Number(this).toFixed(len))
}

Number.prototype.toFileSize = function() {
  return filesize(this)
}

/**
 * md5
 * @param str
 * @returns {string}
 * @private
 */
String.prototype.toMD5 = function() {
  const checksum = crypto.createHash('md5')
  checksum.update(this)
  return checksum.digest('hex')
}

String.prototype.toAscii = function() {
  const hex = this.toString()
  let str = ''
  for (let i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
  }
  return str
}

String.prototype.toHex = function () {
  let arr = []
  for (let i = 0, l = this.length; i < l; i++) {
    let hex = Number(this.charCodeAt(i)).toString(16)
    arr.push(hex)
  }
  return arr.join('')
}

String.prototype.mask = function(firstPosition = 2) {
  if (this.length <= firstPosition) return this

  const destStr = this.slice(firstPosition)
  const reg = new RegExp(destStr, 'gi')

  let prefix = ''
  for (let i = 0; i < destStr.length; i++) {
    prefix = prefix + '*'
  }

  return this.replace(reg, prefix)
}

String.prototype.replaceTo = function(find, change) {
  const reg = new RegExp(find, 'gi')
  return this.replace(reg, change)
}

String.prototype.toBase64 = function() {
  const b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  let o1 = 0
  let o2 = 0
  let o3 = 0
  let h1 = 0
  let h2 = 0
  let h3 = 0
  let h4 = 0
  let bits = 0
  let i = 0

  let ac = 0
  let enc = ''
  const tmpArr = []

  if (!this) {
    return this
  }

  do { // pack three octets into four hexets
    o1 = this.charCodeAt(i++)
    o2 = this.charCodeAt(i++)
    o3 = this.charCodeAt(i++)

    bits = o1 << 16 | o2 << 8 | o3

    h1 = bits >> 18 & 0x3f
    h2 = bits >> 12 & 0x3f
    h3 = bits >> 6 & 0x3f
    h4 = bits & 0x3f

    tmpArr[ac++] = b64.charAt(h1) + b64.charAt(h2) + b64.charAt(h3) + b64.charAt(h4)
  } while (i < this.length)

  enc = tmpArr.join('')

  let r = this.length % 3

  return (r ? enc.slice(0, r - 3) : enc) + '==='.slice(r || 3)
}

String.prototype.toSHA1 = function() {
  const checksum = crypto.createHash('sha1')
  checksum.update(this)
  return checksum.digest('hex')
}

String.prototype.toSHA256 = function() {
  const checksum = crypto.createHash('sha256')
  checksum.update(this)
  return checksum.digest('hex')
}

String.prototype.toHMACSHA256BASE64 = function (secret){

  const checksum = crypto.createHmac('SHA256', secret)
  checksum.update(this)
  return checksum.digest('base64')
}