import {createApp} from 'vue'
import '@/helpers/define.helper'

// container
import './libraries/mutex'

import App from './App.vue'
import router from "./router"
// import BootstrapVue3 from 'bootstrap-vue-3'
import vClickOutside from "click-outside-vue3"
import {registerScrollSpy} from 'vue3-scroll-spy'
import VueLogger from 'vuejs3-logger'

//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/js/languages/ko'
import 'froala-editor/js/languages/en_gb'
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min'
import 'froala-editor/js/third_party/font_awesome.min'
// import 'froala-editor/js/third_party/spell_checker.min'
import 'froala-editor/js/third_party/image_tui.min'
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'

// import VueFroala from 'vue-froala-wysiwyg'

import Maska from 'maska'
import {i18n} from "./i18n.js"

import API from '@/services'

import 'sweetalert2/dist/sweetalert2.min.css'
import '@vueform/slider/themes/default.css'

import index from "./store"

import "../src/design/app.scss"

import VueNumberFormat from 'vue-number-format'

// highlight.js
/*
import VueHighlightJS from 'vue3-highlightjs'
import 'highlight.js/styles/solarized-dark.css'

import css from 'highlight.js/lib/languages/css'
import javascript from 'highlight.js/lib/languages/javascript'
import json from 'highlight.js/lib/languages/json'
import php from 'highlight.js/lib/languages/php'
import java from 'highlight.js/lib/languages/java'
*/
import '@vuepic/vue-datepicker/dist/main.css'

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import 'vue-next-select/dist/index.css'

const isProduction = process.env.NODE_ENV === 'production'
const loggerOptions = {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '>>>',
    showConsoleColors: true
}

createApp(App)
    .use(VueLoading)
    /*
    .use(VueHighlightJS, {
        languages: {
            css,
            php,
            java,
            javascript,
            json
        }
    })
     */
    // .use(VueFroala)
    .use(VueNumberFormat)
    .use(VueLogger, loggerOptions)
    .use(index)
    .use(router)
    .use(API)
    // .use(BootstrapVue3)
    .use(vClickOutside)
    .use(i18n)
    .use(registerScrollSpy)
    .use(Maska)
    .mount('#app')
